<template>
  <div v-if="Object.keys(newsEvents).length" class="home-news-events container">
    <public-title :data-info="dataInfo" />
    <div class="news-events">
      <div v-if="Object.keys(newsEvents.news).length" class="news-box">
        <router-link :to="`/${$route.params.site}/${$route.params.lang}/newsDetail?id=${newsEvents.news.id}`" class="items">
          <div class="img-bg">
            <div class="img-box" :style="`background: url(${newsEvents.news.image}) center center / cover no-repeat`"></div>
          </div>
          <div class="text-box">
            <h4 class="name">{{ newsEvents.news.name }}</h4>
            <h5 :title="newsEvents.news.description" class="desc" v-html="newsEvents.news.description"></h5>
          </div>
        </router-link>
      </div>
      <div v-if="Object.keys(newsEvents.events).length" class="events-box">
        <a class="items">
          <div class="img-bg">
            <div class="img-box" :style="`background: url(${newsEvents.events.image}) center center / cover no-repeat`"></div>
          </div>
          <div class="text-box">
            <h4 class="name ellipsis">{{ newsEvents.events.title }}</h4>
            <h5 :title="newsEvents.events.description" class="desc" v-html="newsEvents.events.description"></h5>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle
  },
  props: {
    newsEventsTitle: {
      type: String,
      default: ''
    },
    newsEvents: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataInfo: {
        title: ''
      }
    }
  },
  watch: {
    newsEventsTitle: {
      handler(val) {
        this.dataInfo.title = val
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.home-news-events {
  .news-events {
    display: flex;
    flex: 1;
    .news-box {
      width: 66%;
      max-width: 848px;
      height: 280px;
      background: #F5F5F5;
      border-radius: 10px;
      .items {
        display: flex;
        &:hover {
          .img-bg {
            .img-box {
              .scale();
            }
          }
          .text-box {
            .name {
              color: @theme;
            }
          }
        }
        .img-bg {
          width: 50%;
          height: 280px;
          border-radius: 10px 0 0 10px;
          overflow: hidden;
          .img-box {
            width: 100%;
            height: 100%;
            .transition();
          }
        }
        .text-box {
          width: 50%;
          padding: 24px 16px;
          .name {
            font-size: 20px;
            font-weight: 600;
            color: #232931;
            line-height: 27px;
            height: 54px;
            overflow: hidden;
            .transition();
          }
          .desc {
            font-size: 14px;
            color: #606266;
            line-height: 21px;
            margin-top: 16px;
            max-height: 88px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .events-box {
      width: calc(34% - 18px);
      margin-left: 18px;
      background: #F5F5F5;
      border-radius: 10px;
      .items {
        &:hover {
          .img-bg {
            .img-box {
              .scale();
            }
          }
          .text-box {
            .name {
              color: @theme;
            }
          }
        }
        .img-bg {
          height: 140px;
          border-radius: 10px 10px 0 0;
          overflow: hidden;
          .img-box {
            height: 100%;
            .transition();
          }
        }
        .text-box {
          padding: 18px 16px;
          .name {
            font-size: 20px;
            font-weight: 600;
            color: #232931;
            line-height: 19px;
            .transition();
          }
          .desc {
            font-size: 14px;
            color: #232931;
            line-height: 21px;
            margin-top: 18px;
            max-height: 63px;
            overflow: hidden;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .news-events {
      flex-wrap: wrap;
      .news-box {
        width: 100%;
        height: 220px;
        border-radius: 10px;
        .items {
          .img-bg {
            height: 220px;
          }
          .text-box {
            .name {
              font-size: 16px;
              line-height: 24px;
              height: 50px;
            }
            .desc {
              margin-top: 12px;
            }
          }
        }
      }
      .events-box {
        width: 100%;
        margin: 16px 0 0 0;
        border-radius: 10px;
        .items {
          .text-box {
            .name {
              font-size: 16px;
              line-height: 19px;
            }
            .desc {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
