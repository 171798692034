<template>
  <div class="consumer-videos-wrap">
    <public-title :data-info="dataInfo" />
    <swiper ref="videosSwiper" class="videos-list" :options="videosSwiperOption">
      <swiper-slide v-for="(item, index) in video" :key="index">
        <div class="video-img-top">
          <video
            :src="item.video"
            x5-video-player-type="h5"
            webkit-playsinline="true"
            playsinline="true"
            class="video-player"
            :poster="item.image"
          ></video>
          <div class="video-icon-box" @click="videoHandler(index)">
            <i class="iconfont icon-a-playCircle1x"></i>
          </div>
        </div>
        <div v-if="item.title" class="video-bottom" @click="handleLink(item)">
          <div class="video-text">
            {{ item.title }}
          </div>
          <span v-if="item.product_name" class="title">{{ LP.lang_related_products }}</span>
          <span class="model" v-html="item.product_name"></span>
        </div>
      </swiper-slide>
      <div slot="button-prev" class="videos-button-prev">
        <span class="icon el-icon-arrow-left"></span>
      </div>
      <div slot="button-next" class="videos-button-next">
        <span class="icon el-icon-arrow-right"></span>
      </div>
    </swiper>
    <div class="learnMorediv">
      <a :href='`/${$route.params.site}/${$route.params.lang}/videos`'>
        <p>{{ LP.lang_learn_more }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle,
    swiper,
    swiperSlide,
  },
  props: {
    video: {
      type: Array,
      default: () => [],
    },
    videosTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataInfo: {
        title: '',
      },
      videosSwiperOption: {
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        slidesPerView: 3, // 每屏显示个数
        spaceBetween: 40,
        loop: false,
        slidesPerGroup: 3, // 拖动个数
        centerInsufficientSlides: true, // 居中
        allowTouchMove: true, // 是否可拖动
        navigation: {
          // 前进后退按钮
          nextEl: '.videos-button-next',
          prevEl: '.videos-button-prev',
          disabledClass: 'videos-button-disabled',
        },
        breakpoints: {
          768: {
            slidesPerView: 1, // 每屏显示个数
            slidesPerGroup: 1,
            spaceBetween: 20,
          },
        },
      },
    }
  },
  watch: {
    videosTitle: {
      handler(val) {
        this.dataInfo.title = val
      },
      deep: true,
    },
  },
  methods: {
    // 视频播放
    videoHandler(index) {
      document.getElementsByClassName('video-player')[index].controls = true
      document.getElementsByClassName('video-player')[index].play()
      document.getElementsByClassName('video-player')[index].poster = ''
      document.getElementsByClassName('video-icon-box')[index].style.display = 'none'
    },
    // 跳转详情页
    handleLink(item) {
      this.$router.push(
        `/${this.$route.params.site}/${this.$route.params.lang}/products/productDetail?product_id=${item.product_id}`,
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.consumer-videos-wrap {
  .videos-list {
    /deep/ .swiper-wrapper {
      .video-img-top {
        position: relative;
        height: 235px;
        .video-player {
          width: 100%;
          height: 100%;
          max-height: 235px;
          border-radius: 12px;
          object-fit: cover;
        }
        .video-icon-box {
          cursor: pointer;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          & > .iconfont {
            font-size: 64px;
            color: #5f6266;
            transition: all 0.3s;
          }
        }
        &:hover {
          .video-icon-box {
            & > .iconfont {
              opacity: 0.7;
            }
          }
        }
      }
      .video-bottom {
        margin-top: 16px;
        padding: 0 10px;
        cursor: pointer;
        .video-text {
          font-size: 16px;
          line-height: 24px;
        }
        .title {
          font-size: 14px;
          line-height: 22px;
        }
        .model {
          font-size: 14px;
          line-height: 22px;
          color: #606266;
          margin-left: 10px;
        }
        &:hover {
          .title,
          .model {
            color: @theme;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .videos-button-prev,
    .videos-button-next {
      position: absolute;
      z-index: 100;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      .icon {
        font-size: 40px;
        color: #fff;
        line-height: 50px;
        text-align: center;
        width: 50px;
        height: 50px;
      }
    }
    .videos-button-prev {
      left: 0;
    }
    .videos-button-next {
      right: 0;
    }
    .videos-button-disabled {
      opacity: 0.1;
    }
  }
  .learnMorediv {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    p {
      width: 332px;
      height: 40px;
      border-radius: 104px;
      background: #a1a1a1;
      color: #fff;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      cursor: pointer;
      &:hover{
        background: #000000;
      }
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    /deep/ .public-title {
      .text-box {
        padding: 38px 0 16px;
      }
    }
    .videos-list {
      position: static;
      /deep/ .swiper-wrapper {
        .video-img-top {
          .video-icon-box {
            & > .iconfont {
              font-size: 53px;
            }
          }
          .video-text {
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
      .videos-button-prev,
      .videos-button-next {
        top: 32px;
        transform: translateY(0);
      }
    }
  }
}
</style>
