import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  home: `/home/index` // 首页
}

// 语言
export const getHomeInfo = () => fetch(`${apiUrl.home}/${lang}/${site}`)
