<template>
  <div class="home-banner-wrap">
    <div v-if="banner && banner.length > 0" class="banner-box">
      <div class="banner-content">
        <!-- :options="homeBannerSwiperOption" -->
        <swiper ref="homeSwiper" class="home-swiper">
          <swiper-slide v-for="(item, index) in banner" :key="index">
            <a
              :href="`${
                item.linktype === '1'
                  ? `/${$route.params.site}/${$route.params.lang}${item.linkurl}`
                  : item.linktype === '0'
                  ? `javascript:;`
                  : item.linkurl
              }`"
              class="btn-link"
              :class="item.linktype === '0' ? 'cursor-default' : ''"
              :style="`color: ${item.button_color}`"
            >
              <div
                v-if="item.show_type === '1'"
                class="banner-img show-banner-pc"
                :style="`background: url(${item.image}) center center / cover no-repeat`"
              >
                <div class="text-box">
                  <div class="container text-content">
                    <h1 class="title" :style="`color: ${item.color}`">{{ item.title }}</h1>
                    <h5 class="subtitle" :style="`color: ${item.color}`">{{ item.description }}</h5>
                    <span
                      v-if="item.linktype !== '0' && item.button === '1'"
                      class="banner-btn"
                      :style="`backgroundColor: ${item.button_background_color}`"
                    >
                      {{ LP.lang_learn_more }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="banner-img show-banner-mobile"
                :style="`background: url(${item.mobile_image}) center center / cover no-repeat`"
              ></div>
              <div v-if="item.show_type !== '1' && item.brief_video">
                <video :src="item.brief_video" x5-video-player-type="h5" class="video-box" autoplay controls></video>
              </div>
            </a>
          </swiper-slide>
        </swiper>
        <div class="swiper-home-pagination"></div>
        <div slot="button-prev" class="home-button-prev">
          <span class="iconfont icon-a-zuojiantou1x"></span>
        </div>
        <div slot="button-next" class="home-button-next">
          <span class="iconfont icon-a-youjiantou1x"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
import Swiper from 'swiper/dist/js/swiper.min.js'
import { mapState } from 'vuex'

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      banner: (state) => state.common.banner,
    }),
  },
  async created() {
    this.$store.commit('common/SET_BANNER', [])
    if (this.$route.fullPath.includes('business')) {
      await this.$store.dispatch('common/getBanner', { menu_id: 1 })
    } else {
      await this.$store.dispatch('common/getBanner', { menu_id: 3 })
    }
    window.onload = this.getOptions()
  },
  methods: {
    // 获取swiper配置  （写在methods可以避免与其他页面swiper发生冲突）
    getOptions() {
      var swiper = new Swiper('.home-swiper', {
        autoplay: {
          delay: 5000,
        },
        loop: true,
        slidesPerView: 'auto', // 每屏显示个数
        allowTouchMove: true, // 是否可拖动
        pagination: {
          el: '.swiper-home-pagination',
          clickable: true,
        },
        navigation: {
          // 前进后退按钮
          nextEl: '.home-button-next',
          prevEl: '.home-button-prev',
        },
      })
      return swiper
    },
  },
}
</script>

<style lang="less" scoped>
.home-banner-wrap {
  .banner-box {
    width: 100%;
    height: 800px;
    position: relative;
    .home-swiper {
      .banner-img {
        height: 800px;
        .text-box {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          .text-content {
            .title {
              font-size: 48px;
              font-weight: bold;
              line-height: 48px;
            }
            .subtitle {
              font-size: 18px;
              font-weight: 400;
              line-height: 26px;
              margin: 24px 0 48px;
            }
            .banner-btn {
              display: inline-block;
              padding: 12px 24px;
              border-radius: 19px;
              border: 1px solid rgba(255, 255, 255, 0.53);
              .btn-link {
                font-size: 16px;
                font-weight: 600;
                color: #ffffff;
                line-height: 16px;
              }
            }
          }
        }
      }
      .show-banner-mobile {
        display: none;
      }
      .video-box {
        height: 800px;
        width: 100%;
        object-fit: cover;
      }
      .cursor-default:hover {
        cursor: default;
      }
    }
    .swiper-home-pagination {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      /deep/ .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        cursor: pointer;
        margin-left: 24px;
        background: transparent;
        border: 1px solid #979797;
        opacity: 1;
      }
      /deep/ .swiper-pagination-bullet-active {
        background: #ffffff;
      }
    }
    .home-button-prev {
      left: 48px;
    }
    .home-button-next {
      right: 48px;
    }
    .home-button-next,
    .home-button-prev {
      cursor: pointer;
      position: absolute;
      top: 50%;
      z-index: 10;
      cursor: pointer;
      .iconfont {
        color: #fff;
        font-size: 54px;
        padding: 10px;
        border-radius: 50%;
        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
    .swiper-button-disabled {
      .iconfont {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .banner-box {
      .home-swiper {
        .banner-img {
          .text-box {
            .text-content {
              max-width: 1150px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .banner-box {
      .home-swiper {
        .banner-img {
          .text-box {
            .text-content {
              width: 76%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 998px) {
    .banner-box {
      .home-swiper {
        .banner-img {
          .text-box {
            .text-content {
              width: 72%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .banner-box {
      height: 488px;
      margin-top: 92px;
      .home-swiper {
        .banner-img {
          height: 488px;
          .text-box {
            top: 48px;
            transform: translateY(0);
            text-align: center;
            .text-content {
              width: 92%;
              .title {
                font-size: 32px;
                line-height: 32px;
              }
              .subtitle {
                font-size: 16px;
                line-height: 20px;
              }
              .banner-btn {
                padding: 12px 22px;
              }
            }
          }
        }
        .video-box {
          height: 488px;
        }
      }
      .home-button-prev,
      .home-button-next {
        display: none;
      }
    }
  }
}
</style>
