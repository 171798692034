<template>
  <div class="business-solution-wrap">
    <public-title :data-info="solutionData" />
    <div class="solution-wrap">
      <div class="container">
        <div class="search-tabs">
          <ul class="search-tabs-list">
            <li
              v-for="(item, index) in solutionsList"
              :key="index"
              class="search-tabs-item"
              :class="{active: tabShowIndex === index}"
              @click="changHandle(item, index)"
            >
              <span class="name">{{ item.title }}</span>
            </li>
          </ul>
        </div>
        <div v-for="(item, index) in solutionsList" :key="index" class="tabs-box">
          <div v-if="tabShowIndex === index" class="tabs-content">
            <div class="left-content">
              <div class="title">{{ item.name }}</div>
              <div class="sub-title">{{ item.name_description }}</div>
              <div class="des">{{ item.name_content }}</div>
              <div class="btn">
                <span class="link" @click="linkHandle(item.id)">{{ LP.lang_learn_more }}</span>
              </div>
            </div>
            <div class="right-img">
              <div class="img-bg" :style="`background: url(${item.image}) center center / cover no-repeat`"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'

export default {
  components: {
    PublicTitle
  },
  props: {
    solutionsList: {
      type: Array,
      default: () => []
    },
    solutionsTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      solutionData: {
        title: 'Solutions'
      },
      tabShowIndex: 0
    }
  },
  watch: {
    solutionsTitle(val) {
      this.solutionData.title = val
    }
  },
  methods: {
    // 切换数据
    changHandle(item, index) {
      this.tabShowIndex = index
    },
    // 跳转解决方案详情
    linkHandle(id) {
      this.$router.push(`/${this.$route.params.site}/${this.$route.params.lang}/Solutions/detail?id=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.business-solution-wrap {
  .solution-wrap {
    width: 100%;
    background-color: #F5F5F5;
    /deep/ .public-nav-page {
      .nav-fixed {
        position: static;
      }
    }
    .tabs-box {
      .tabs-content {
        display: flex;
        justify-content: space-between;
        padding: 31px 0;
        .left-content {
          flex: 1;
          padding-top: 50px;
          padding-right: 30px;
          max-width: 578px;
          max-height: 400px;
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #232931;
            line-height: 32px;
          }
          .sub-title {
            font-size: 18px;
            color: #232931;
            line-height: 24px;
            margin: 10px 0 30px;
          }
          .des {
            height: 58px;
            overflow: hidden;
            white-space: normal;
            font-size: 14px;
            color: #606266;
            line-height: 19px;
            margin-bottom: 60px;
          }
          .btn {
            width: 127px;
            height: 40px;
            text-align: center;
            background-color: rgba(14, 95, 172, 1);
            border-radius: 4px;
            .transition;
            .link {
              cursor: pointer;
              display: inline-block;
              width: 100%;
              height: 100%;
              font-size: 16px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 40px;
            }
            &:hover {
              background-color: rgba(14, 95, 172, 0.8);
            }
          }
        }
        .right-img {
          flex: 1;
          max-height: 400px;
          .img-bg {
            height: 400px;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .search-tabs {
    border-bottom: 2px solid #DDDDDD;
    .search-tabs-list {
      display: flex;
      justify-content: left;
      .search-tabs-item {
        padding: 19px 0 21px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        & + .search-tabs-item {
          margin-left: 96px;
        }
        .name {
          font-size: 18px;
          font-weight: 600;
          color: #979797;
          line-height: 24px;
          display: block;
          white-space: nowrap;
          .transition();
        }
        &:hover {
          .name {
            color: #232931;
          }
        }
        &.active {
          border-bottom: 2px solid #232931;
          .name {
            color: #232931;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    /deep/ .public-title {
      .text-box {
        padding: 22px 16px;
      }
    }
    .solution-wrap {
      .tabs-box {
        .tabs-content {
          display: block;
          padding: 0;
          padding-bottom: 24px;
          .left-content {
            padding-top: 24px;
            padding-right: 0;
            .title {
              font-size: 18px;
              line-height: 18px;
            }
            .sub-title {
              margin-bottom: 10px;
              font-size: 16px;
              line-height: 16px;
            }
            .des {
              height: 100%;
              margin-bottom: 24px;
            }
            .btn  {
               height: 100%;
              .link {
                padding: 10px 20px;
                line-height: 21px;
              }
            }
          }
          .right-img {
            margin-top: 24px;
            height: 210px;
            .img-bg {
              height: 210px;
            }
          }
        }
      }
    }
    .search-tabs {
      .search-tabs-list {
        overflow-x: auto;
        justify-content: left;
        .search-tabs-item {
          padding: 14px 0;
          .name {
            font-size: 14px;
            line-height: 20px;
          }
          & + .search-tabs-item {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
