<template>
  <div class="consumer-tv-wrap">
    <div
      v-for="(item, index) in productMenuList"
      :key="index"
      class="tv-box"
      :class="productMenuList.length < 3 ? 'tv-flex' : 'tv-width'"
      @click="clickHandle(item)"
    >
      <div class="title">{{ item.menu_name }}</div>
      <div class="img-box">
        <img :src="item.menu_image" alt="" class="consumer-tv-img" />
        <!-- <div class="btn">
          {{ LP.lang_learn_more }}
          <i class="iconfont icon-a-youjiantou1x"></i>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productMenuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    clickHandle(item) {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/products/productCategories`,
        query: {
          menu_id: item.menu_id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.consumer-tv-wrap {
  display: flex;
  flex-wrap: wrap;
  .tv-flex {
    flex: 1;
  }
  .tv-width {
    width: calc(100% / 3);
    box-sizing: border-box;
  }
  .tv-box {
    padding: 0 10px;
    text-align: center;
    &:last-child {
      margin-right: 0;
    }
    .title {
      padding: 72px 0 24px 0;
      font-size: 36px;
      font-weight: 700;
      color: #232931;
      line-height: 36px;
    }
    .img-box {
      position: relative;
      cursor: pointer;
      width: 100%;
      max-height: 320px;
      overflow: hidden;
      border-radius: 12px;
      .consumer-tv-img {
        width: 100%;
        min-height: 210px;
        .transition;
      }
      // .btn {
      //   position: absolute;
      //   bottom: 15px;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   font-size: 18px;
      //   color: #232931;
      //   line-height: 18px;
      //   .transition;
      // }
      &:hover {
        .consumer-tv-img {
          .scale();
        }
        .btn {
          color: @theme;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 22px;
    display: block;
    .tv-width {
      width: 100%;
    }
    .tv-box {
      margin-right: 0;
      .title {
        padding: 10px 0;
        font-size: 24px;
        line-height: 24px;
      }
    }
    .tv-width {
      width: 100%;
    }
  }
}
</style>
