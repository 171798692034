<template>
  <div v-if="mostProducts && mostProducts.length" class="most-popular-wrap">
    <public-title :data-info="dataInfo" />
    <swiper ref="popularSwiper" class="popular-swiper" :options="MostPopularSwiperOption">
      <swiper-slide v-for="(item, index) in mostProducts" :key="index">
        <div class="popular-img" @click="clickHandler(item)">
          <img :src="item.product_thumb" alt="" />
        </div>
        <div class="popular-title ellipsis" @click="clickHandler(item)">{{ item.external_model }}</div>
      </swiper-slide>
      <div slot="button-prev" class="popular-button-prev">
        <span class="icon el-icon-arrow-left"></span>
      </div>
      <div slot="button-next" class="popular-button-next">
        <span class="icon el-icon-arrow-right"></span>
      </div>
    </swiper>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle,
    swiper,
    swiperSlide,
  },
  props: {
    mostProducts: {
      type: Array,
      default: () => [],
    },
    mostProductsTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataInfo: {
        title: '',
      },
      MostPopularSwiperOption: {
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: false,
        centerInsufficientSlides: true, // 居中
        slidesPerView: 'auto', // 每屏显示个数
        slidesPerGroup: 3, // 拖动个数
        allowTouchMove: true, // 是否可拖动
        navigation: {
          // 前进后退按钮
          nextEl: '.popular-button-next',
          prevEl: '.popular-button-prev',
          disabledClass: 'popular-button-disabled',
        },
        breakpoints: {
          768: {
            slidesPerGroup: 2,
          },
        },
      },
    }
  },
  watch: {
    mostProductsTitle: {
      handler(val) {
        this.dataInfo.title = val
      },
      deep: true,
    },
  },
  methods: {
    // 跳转到产品详情页
    clickHandler(item) {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/products/productDetail`,
        query: {
          product_id: item.product_id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.most-popular-wrap {
  .popular-swiper {
    /deep/ .swiper-wrapper {
      .swiper-slide {
        cursor: pointer;
        max-width: 200px;
        height: 100%;
        margin: 0 24px;
        .popular-img {
          width: 200px;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
            .transition;
          }
        }
        .popular-title {
          text-align: center;
          font-size: 16px;
          color: #232931;
          line-height: 18px;
          .transition;
        }
        &:hover {
          .popular-img {
            img {
              .scale;
            }
          }
          .popular-title {
            color: @theme;
          }
        }
      }
    }
    .popular-button-prev,
    .popular-button-next {
      position: absolute;
      z-index: 100;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      cursor: pointer;
      background: rgba(0,0,0,.2);
      border-radius: 50%;
      .icon {
        font-size: 40px;
        color: #fff;
        line-height: 50px;
        text-align: center;
        width: 50px;
        height: 50px;
      }
    }
    .popular-button-prev {
      left: 0;
    }
    .popular-button-next {
      right: 0;
    }
    .popular-button-disabled {
      opacity: 0.1;
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    /deep/ .public-title {
      .text-box {
        padding-top: 32px;
        .title {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
    .popular-swiper {
      position: static;
      /deep/ .swiper-wrapper {
        .swiper-slide {
          max-width: 160px;
          margin: 0 12px;
          .popular-img {
            width: 160px;
            height: 160px;
          }
          .popular-title {
            font-size: 14px;
          }
        }
      }
      .popular-button-prev,
      .popular-button-next {
        top: 32px;
        transform: translateY(0);
      }
    }
  }
}
/deep/ .public-title {
  .text-box {
    padding-bottom: 0px;
  }
}
</style>
