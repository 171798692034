<template>
  <div class="home-wrap">
    <home-banner />
    <div v-if="showsite" class="home-consumer container">
      <consumer-monitors :product-menu-list="monitorsMenuList" />
      <most-popular :most-products="mostProducts" :most-products-title="mostProductsTitle" />
      <consumer-videos :videos-title="videosTitle" :video="video" />
    </div>
    <div v-else class="home-business">
      <business-products :product-menu-list="productMenuList" :products-title="productsTitle" />
      <business-solution :solutions-list="solutionsList" :solutions-title="solutionsTitle" />
      <news-events :news-events-title="newsEventsTitle" :news-events="newsEvents" />
      <business-support :support="support" />
    </div>
  </div>
</template>

<script>
import HomeBanner from '@/components/home/HomeBanner.vue'
import ConsumerMonitors from '@/components/home/ConsumerMonitors.vue'
import MostPopular from '@/components/home/MostPopular.vue'
import ConsumerVideos from '@/components/home/ConsumerVideos.vue'
import BusinessProducts from '@/components/home/BusinessProducts.vue'
import BusinessSolution from '@/components/home/BusinessSolution.vue'
import NewsEvents from '@/components/home/NewsEvents.vue'
import BusinessSupport from '@/components/home/BusinessSupport.vue'
import { getHomeInfo } from '@/api/home'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    HomeBanner,
    ConsumerMonitors,
    MostPopular,
    ConsumerVideos,
    BusinessProducts,
    BusinessSolution,
    NewsEvents,
    BusinessSupport
  },
  data() {
    return {
      mostProducts: [],
      mostProductsTitle: '',
      productMenuList: [],
      productsTitle: '',
      solutionsList: [],
      solutionsTitle: '',
      video: [],
      videosTitle: '',
      support: [],
      monitorsMenuList: [],
      showsite: true,
      newsEventsTitle: '',
      newsEvents: {}
    }
  },
  computed: {
    ...mapState({
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  watch: {
    '$route'(to, from) {
      if (to.params.site !== from.params.site) {
        this.getData()
      }
    }
  },
  mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    this.getData()
  },
  methods: {
    async getData() {
      this.$route.fullPath.includes('business') ? this.showsite = false : this.showsite = true
      await getHomeInfo().then(res => {
        this.$store.commit('common/SET_MATE', res.data.seo)
        this.mostProducts = res.data.most_products
        this.mostProductsTitle = res.data.most_products_title
        this.solutionsList = res.data.solutionList
        this.solutionsTitle = res.data.solutions_title
        this.video = res.data.video
        this.videosTitle = res.data.videos_title
        this.support = res.data.support
        if (this.$route.fullPath.includes('business')) {
          this.productMenuList = res.data.productMenuList
          this.productsTitle = res.data.products_title
          this.newsEventsTitle = res.data.lang_news_events
          this.newsEvents = res.data.news_events
        } else {
          this.monitorsMenuList = res.data.productMenuList
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.home-wrap {
  .home-consumer {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 768px) {
    .home-consumer {
      padding-bottom: 22px;
    }
  }
}
</style>
