<template>
  <div class="business-product-wrap container">
    <public-title :data-info="productsData" />
    <div v-if="productMenuList.length" class="products-content">
      <!-- <div class="left-box" @click="clickHandle(productMenuList[0].menu_id)">
        <div :style="`background: url(${productMenuList[0].menu_image}) center center / cover no-repeat`" class="content-img"></div>
        <div class="img-des" :style="`color: ${productMenuList[0].color}`">{{ productMenuList[0].menu_name }}</div>
      </div> -->
      <div class="right-box">
        <ul class="right-list">
          <li
            v-for="(item, index) in shortList"
            :key="index"
            class="right-item"
            @click="clickHandle(item)"
          >
            <div :style="`background: url(${item.menu_image}) center center / cover no-repeat`" class="item-img"></div>
            <div class="img-des" :style="`color: ${item.color}`">{{ item.menu_name }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle
  },
  props: {
    productMenuList: {
      type: Array,
      default: () => []
    },
    productsTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      productsData: {
        title: ''
      },
      shortList: []
    }
  },
  watch: {
    productsTitle: {
      handler(val) {
        this.productsData.title = val
      },
      deep: true
    },
    productMenuList(val) {
      if (val) {
        // this.shortList = JSON.parse(JSON.stringify(val)).splice(1)
        this.shortList = val
      }
    }
  },
  methods: {
    // 跳转产品页
    clickHandle(item) {
      // 跳转外链
      if (item.menu_keywords) {
        window.open(item.menu_keywords)
      } else {
        this.$router.push({
          path: `/${this.$route.params.site}/${this.$route.params.lang}/products/productCategories`,
          query: {
            menu_id: item.menu_id
          }
        })
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.business-product-wrap {
  .products-content {
    display: flex;
    .left-box {
      position: relative;
      height: 571px;
      width: 41%;
      margin-right: 16px;
      overflow: hidden;
      border-radius: 20px;
      .content-img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        .transition;
      }
      .img-des {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
      }
      &:hover {
        .content-img {
          .scale;
        }
      }
    }
    .right-box {
      width: 100%;
      .right-list {
        .right-item {
          position: relative;
          display: inline-block;
          width: calc((100% - 32px) / 3);
          height: 278px;
          margin-right: 16px;
          margin-bottom: 15px;
          overflow: hidden;
          border-radius: 12px;
          cursor: pointer;
          .item-img {
            width: 100%;
            height: 100%;
            .transition;
          }
           .img-des {
            position: absolute;
            top: 20px;
            left: 20px;
            font-size: 20px;
            font-weight: bold;
            line-height: 20px;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            .item-img {
              .scale;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .products-content {
      .right-box {
        .right-list {
          .right-item {
            width: calc((100% - 16px) / 2);
            &:nth-child(3n) {
              margin-right: 16px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    /deep/ .public-title {
      .text-box {
        padding: 38px 0 16px;
      }
    }
    .products-content {
      display: block;
      .left-box {
        height: 320px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        .img-des {
          font-size: 18px;
          line-height: 18px;
        }
      }
      .right-box {
        width: 100%;
        .right-list {
          .right-item {
            width: 100%;
            height: 200px;
            margin-right: 0;
            margin-bottom: 10px;
            &:nth-child(n+2) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
