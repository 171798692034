<template>
  <div class="business-solution-wrap container">
    <ul class="support-list">
      <li v-for="(item, index) in support" :key="index" class="support-item">
        <a :href="`/${$route.params.site}/${$route.params.lang}/${item.nav_link_name}`" class="item-link">
          <div class="icon-box">
            <i :class="['icon', 'iconfont', item.nav_iconfont]"></i>
          </div>
          <div class="item-des">{{ item.nav_description }}</div>
          <div class="item-title">{{ item.nav_name }}</div>
        </a>
        <div class="right-line" :class="{'none': index === support.length-1 }"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    support: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.business-solution-wrap {
  .support-list {
    display: flex;
    .support-item {
      display: flex;
      flex: 1;
      padding: 100px 0 80px;
      .item-link {
        display: inline-block;
        width: 100%;
        height: 100%;
        &:hover {
          .icon-box,
          .item-des,
          .item-title {
            color: @theme;
          }
        }
        .icon-box {
          text-align: center;
          height: 40px;
          .transition;
          .icon {
            font-size: 40px;
          }
        }
        .item-des {
          margin: 25px 0 30px;
          text-align: center;
          font-size: 14px;
          color: #606266;
          line-height: 19px;
          .transition;
        }
        .item-title {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #232931;
          line-height: 18px;
          .transition;
        }
      }
      .right-line {
        align-self: center;
        width: 2px;
        height: 138px;
        margin: 0 20px;
        background-color: #ddd;
      }
      .none {
        display: none
      }
    }
  }
  @media screen and (max-width: 768px) {
    .support-list {
      display: block;
      padding: 32px 0;
      .support-item {
        display: block;
        padding: 0;
        .item-link {
          .item-des {
            margin: 9px 0;
            line-height: 21px;
          }
        }
        .right-line {
          margin: 16px auto;
          height: 1px;
          width: 259px;
        }
      }
    }
  }
}
</style>
